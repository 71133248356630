import {Request} from "../../../models/user";
import {useEffect} from "react";
import {Navigate} from "react-router-dom";

interface Props {
    onCreate: (request: Request) => void
}
export function VolumeView(props: Props) {
    useEffect(() => {
        let request = new Request()
        request.method = 'get_keywords_volume'
        request.locationIds = [2250]
        props.onCreate(request)
    }, [props.onCreate])

    return (
        <Navigate to={'/dashboard/request/new/'} replace={true} />
    )
}