import {
    GoogleKeywordVolume,
    GoogleKeywordVolumeMonthlySearch,
    GoogleLocation
} from "../../models/google";
import {
    GoogleKeywordVolumeFragment,
    GoogleKeywordVolumeMonthlySearchFragment,
    GoogleLocationFragment
} from "./fragments";
import {Request} from "../../models/user";


export class GoogleLocationFactory {
    static fromFragment = (fragment: GoogleLocationFragment): GoogleLocation =>  {
        let location = new GoogleLocation()
        location.resourceName = fragment.resource_name
        location.id = fragment.id
        location.name = fragment.name
        location.countryCode = fragment.country_code
        location.targetType = fragment.target_type
        location.canonicalName = fragment.canonical_name
        return location
    }
}

export class GoogleKeywordVolumeMonthlySearchFactory {
    static fromFragment = (fragment: GoogleKeywordVolumeMonthlySearchFragment): GoogleKeywordVolumeMonthlySearch =>  {
        let monthlySearch = new GoogleKeywordVolumeMonthlySearch()
        monthlySearch.year = fragment.year
        monthlySearch.month = fragment.month
        monthlySearch.count = fragment.count
        return monthlySearch
    }
}

export class GoogleKeywordVolumeFactory {
    static fromFragment = (fragment: GoogleKeywordVolumeFragment): GoogleKeywordVolume =>  {
        let monthlySearches = fragment.monthly_searches.map(
            fragment => GoogleKeywordVolumeMonthlySearchFactory.fromFragment(fragment)
        )

        let volume = new GoogleKeywordVolume()
        volume.id = fragment.id
        volume.location = fragment.location
        volume.keyword = fragment.keyword
        volume.variants = fragment.variants
        volume.monthlySearches = monthlySearches
        volume.filteredMonthlySearches = monthlySearches
        volume.averageCpc = fragment.average_cpc
        volume.competition = fragment.competition
        volume.fromCache = fragment.from_cache
        return volume
    }

    static fromRequest = (request: Request, nbYearsForMetrics: number): GoogleKeywordVolume[] =>  {
        let nb_monthly_searches = 12*nbYearsForMetrics*-1

        return request.keywordVolumes.map((kv) => {
            let min: number | undefined = undefined
            let max: number | undefined = undefined
            let total = 0

            kv.filteredMonthlySearches = kv.monthlySearches.slice(nb_monthly_searches)
            kv.filteredMonthlySearches.forEach((monthlySearch) => {
                if (min === undefined || min > monthlySearch.count) {
                    min = monthlySearch.count
                }
                if (max === undefined || max < monthlySearch.count) {
                    max = monthlySearch.count
                }
                total = total + monthlySearch.count
            })

            let avgMonthlySearches = total / (12*nbYearsForMetrics)
            // Round to hundred
            if (avgMonthlySearches > 100) {
                avgMonthlySearches = Math.round(
                    avgMonthlySearches / 100
                ) * 100
            } else {
                if (avgMonthlySearches > 10) {
                    avgMonthlySearches = Math.round(
                        avgMonthlySearches / 10
                    ) * 10
                } else {
                    avgMonthlySearches = Math.round(avgMonthlySearches)
                }
            }
            kv.monthlySearchesMin = min
            kv.monthlySearchesMax = max
            kv.avgMonthlySearches = avgMonthlySearches
            return kv
        })
    }
}
