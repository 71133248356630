import {Routes, useNavigate} from "react-router-dom";
import {Route} from "react-router";
import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import {Request, Account} from "../../models/user";
import {
    FeedbackTypeformPopover,
    JwtToken,
    Cache,
    PageNotFoundView,
    PlaceHolder
} from "@boomrank/react-components";
import {Config, ConfigContext} from "../../contexts/config";
import {GoogleApi} from "../../services/google/api";
import {RequestFactory} from "../../services/auth/factory";
import {
    GoogleKeywordIdeaVolumeResponseSuccess,
    GoogleKeywordVolumeResponseSuccess, GoogleUsageResponse
} from "../../services/google/responses";
import {AuthApi} from "../../services/auth/api";
import {RequestListSuccess} from "../../services/auth/responses";
import {RequestResultRoute} from "./routes/request/result";
import {RequestEditRoute} from "./routes/request/edit";
import {RequestNewRoute} from "./routes/request/new";
import {RequestLocationRoute} from "./routes/request/location";
import {RequestConfigRoute} from "./routes/request/config";
import {HomeRoute} from "./routes/home";
import {IdeaRoute} from "./routes/idea";
import {VolumeRoute} from "./routes/volume";
import {RequestFragment} from "../../services/auth/fragments";

export const STEP_VOLUMES = 'volumes'
export const STEP_IDEAS = 'ideas'

export const conf = {
    volumes: {
        nb_keyword_max: 200
    },
    ideas: {
        nb_keyword_max: 20
    }
}

interface Props {
    token: JwtToken
    me: Account
}

export function KpiCollector(props: Props) {
    let navigate = useNavigate()

    let [googleQuotasExceeded, setGoogleQuotasExceeded] = useState(false)
    let [googleQuotasExceededLoaded, setGoogleQuotasExceededLoaded] = useState(false)

    let [cacheRequests, setCacheRequests] = useState<Cache<Request>>(new Cache<Request>('id'))

    let [request, setRequest] = useState<Request>()
    let [requestsLoaded, setRequestsLoaded] = useState(false)

    let [config, setConfig] = useState(new Config())
    let [isLoading, setIsLoading] = useState(false)

    let cacheUpdater = (obj: Request, remove = false) => {
        let cache: Cache<any> | undefined
        let setCache: Dispatch<SetStateAction<Cache<any>>> | undefined

        if (obj instanceof Request) {
            cache = cacheRequests
            setCache = setCacheRequests
        }

        if (cache !== undefined && setCache !== undefined) {
            cache.add(obj)
            if (remove) {
                cache.delete(obj)
            }
            setCache({
                ...cache
            })
        }
    }

    let onSubmit = () => {
        if (request) {
            let call = refreshKeywordVolumes
            if (request.method === 'get_keywords_ideas_volume') {
                call = refreshKeywordIdeaVolumes
            }
            call(request)
        }
    }

    let refreshKeywordVolumes = (request: Request) => {
        setIsLoading(true)
        GoogleApi.getKeywordVolume(props.token, request).then(response => {
            if (response.statusCode === 200) {
                response = response as GoogleKeywordVolumeResponseSuccess
                let request = RequestFactory.fromFragment(response.data)
                cacheUpdater(request)
                navigate(`/dashboard/request/${request.id}/`)
            }
            setIsLoading(false)
        })
    }

    let refreshKeywordIdeaVolumes = (request: Request) => {
        setIsLoading(true)
        GoogleApi.getKeywordIdeaVolume(props.token, request).then(response => {
            if (response.statusCode === 200) {
                response = response as GoogleKeywordIdeaVolumeResponseSuccess
                let request = RequestFactory.fromFragment(response.data)
                cacheUpdater(request)
                navigate(`/dashboard/request/${request.id}/`)
            }
            setIsLoading(false)
        })
    }

    let getGoogleUsage = () => {
        setGoogleQuotasExceededLoaded(false)
        GoogleApi.usage(props.token).then((response) => {
            if (response.statusCode >= 200 && response.statusCode < 300) {
                let r = response as GoogleUsageResponse
                setGoogleQuotasExceeded(r.data.quotas_exceeded)
            }
            setGoogleQuotasExceededLoaded(true)
        })
    }

    let getRequests = (url?: string) => {
        AuthApi.getRequests(props.token, url)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as RequestListSuccess
                    let requests = response.data.results.map((customer: RequestFragment) => {
                        return RequestFactory.fromFragment(customer)
                    })
                    let cache = new Cache<Request>('id').add(requests)
                    setCacheRequests(cacheRequests => cacheRequests.merge(cache))

                    if (response.data.next) {
                        return getRequests(response.data.next)
                    }
                }
                setRequestsLoaded(true)
            })
    }

    let onRequestChange = (request: Request) => {
        setRequest({
            ...request
        })
    }

    useEffect(() => {
        getGoogleUsage()
        getRequests()
    }, [props.token])

    return (
        <div>
            {
                (
                    googleQuotasExceededLoaded
                ) ?
                    <ConfigContext.Provider value={{config, setConfig}}>
                        {
                            isLoading ?
                                <PlaceHolder className={'h-32'}/>
                            :
                            <Routes>

                                <Route index element={
                                    <HomeRoute
                                        token={props.token}
                                        cacheRequests={cacheRequests}
                                        cacheUpdater={cacheUpdater}
                                        googleQuotasExceeded={googleQuotasExceeded}
                                        requestsLoaded={requestsLoaded}
                                    />
                                }/>
                                <Route path={'volume/'} element={
                                    <VolumeRoute onCreate={onRequestChange}/>
                                }/>
                                <Route path={'idea/'} element={
                                    <IdeaRoute onCreate={onRequestChange}/>
                                }/>
                                <Route path={'request/new/'} element={
                                    <RequestNewRoute
                                        request={request}
                                        onRequestChange={onRequestChange}
                                        onSubmit={onSubmit}
                                    />
                                }/>
                                <Route path={'request/new/location/'} element={
                                    <RequestLocationRoute
                                        request={request}
                                        onRequestChange={onRequestChange}
                                        token={props.token}
                                        onSubmit={onSubmit}
                                    />
                                }/>
                                <Route path={'request/new/config/'} element={
                                    <RequestConfigRoute
                                        request={request}
                                        onRequestChange={onRequestChange}
                                        onSubmit={onSubmit}
                                    />
                                }/>
                                <Route path={'request/:id/'} element={
                                    <RequestResultRoute
                                        token={props.token}
                                        cacheRequests={cacheRequests}
                                        onClickSearchIdea={refreshKeywordIdeaVolumes}
                                    />
                                }/>
                                <Route path={'request/:id/edit'} element={
                                    <RequestEditRoute
                                        onEdit={onRequestChange}
                                        cacheRequests={cacheRequests}
                                        token={props.token}
                                    />
                                }/>
                                <Route path={'*'} element={
                                    <PageNotFoundView/>
                                }/>
                            </Routes>
                        }

                    </ConfigContext.Provider>
                    :
                    <PlaceHolder className={'h-32'}/>
            }
            <div className={'mt-6 text-xs text-br-blue-300 text-right'}>
                build #{process.env.REACT_APP_RELEASE_BUILD} - {process.env.REACT_APP_RELEASE_DATE}
                <FeedbackTypeformPopover
                    id={'b6tP5J7P'}
                    hidden={{
                        'email': props.me.user.email,
                        'firstname': props.me.user.firstname,
                        'url': window.location.href,
                        'application': 'kpi-collector',
                        'build': `${process.env.REACT_APP_RELEASE_BRANCH}-${process.env.REACT_APP_RELEASE_BUILD}`,
                    }}
                    autoClose={3000}
                />
            </div>
        </div>
    )
}