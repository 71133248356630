import {Breadcrumb, GreenButton, RedButton, GrayButton} from '@boomrank/react-components'
import {useLocation, useNavigate} from "react-router-dom";
import {Request} from "../../../../models/user";
import {KeywordsFormView} from "../../views/request/keyword/form";
import {Navigate} from "react-router-dom";
import React, {useState} from "react";
import {getTranslation} from "../../../../intl";

interface Props {
    request?: Request,
    onRequestChange: (request: Request) => void
    onSubmit: () => void
}

export function RequestNewRoute(props: Props) {
    let [isValid, setIsValid] = useState(false)
    let navigate = useNavigate()
    let location = useLocation()

    if (!props.request) {
        return (
            <Navigate to={'/dashboard/volume/'} />
        )
    }

    let label = getTranslation('BREADCRUMB.KEYWORD').format(
        getTranslation(`TYPE.${props.request.method.toUpperCase()}`)
    )

    let onValid = (isValid: boolean) => {
        setIsValid(isValid)
    }

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/`,
                        content: <i className={'fa-solid fa-home'}/>,
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                    {
                        to: `/dashboard/request/new/`,
                        content: <>{label}</>,
                        title: label
                    },
                ]}
            />

            <div className={'mt-3'}>
                <div className={'lg:flex justify-between'}>
                    <div>&nbsp;</div>
                    <div className={'lg:flex'}>
                        {
                            <>
                                <GreenButton disabled={!isValid} className={'lg:mr-2'} text={getTranslation('BUTTON.NEXT_LOCATION')} onClick={() => {
                                    navigate('/dashboard/request/new/location/')
                                }}/>
                                <RedButton disabled={!isValid}  text={getTranslation('BUTTON.SEARCH')} onClick={props.onSubmit}/>
                            </>
                        }
                    </div>
                </div>
                <KeywordsFormView
                    request={props.request}
                    onRequestChange={props.onRequestChange}
                    onValid={onValid}
                />
            </div>
        </>
    )
}