import {useState} from "react";
import {GoogleLocation} from "../../../../models/google";
import {getTranslation} from "../../../../intl";

interface Props {
    locations: GoogleLocation[]
    suggestedLocations: GoogleLocation[]
    onLocationClick: (locations: GoogleLocation[]) => void
    onSuggestSubmit: (value: string) => void
}

export function LocationForm(props: Props) {
    let [inputContent, setInputContent] = useState<string>()

    let onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputContent && event.key.toLowerCase() === 'enter') {
            props.onSuggestSubmit(inputContent)
        }
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputContent(event.target.value)
    }

    let onClickSuggestion = (location: GoogleLocation): void => {
        let index = props.locations.map(location => location.id).indexOf(location.id)
        if (index === -1) {
            props.onLocationClick([
                ...props.locations,
                location
            ])
        }
    }

    let onClickLocation = (location: GoogleLocation): void => {
        let index = props.locations.map(location => location.id).indexOf(location.id)

        if (index > -1) {
            let locations_copy = [...props.locations]
            locations_copy.splice(index, 1)
            props.onLocationClick(locations_copy)
        }
    }

    return (
        <div className="lg:grid grid-cols-2 gap-3 py-6">
            <div>
                <label>{getTranslation('FORM_LOCATION.LABEL_INPUT')}</label>
                <div>
                    <input
                        type="text"
                        defaultValue={inputContent}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        className={'p-3 border border-1 rounded w-full'}
                    />
                    <div className="text-xs text-gray-500 pt-2">
                        {getTranslation('FORM_LOCATION.HELP_INPUT')}
                    </div>
                    {
                        (props.suggestedLocations.length > 0) &&
                        <div className="mt-4">
                            <label>{getTranslation('FORM_LOCATION.LABEL_SUGGEST')}</label>
                            <div className={'lg:grid grid-cols-2 gap-3'}>
                                {
                                    props.suggestedLocations.map((location, idx) => {
                                        return (
                                            <div key={idx} className={"btn btn-gray text-center align-middle"} onClick={() => onClickSuggestion(location)}>
                                                {location.name}
                                                <div className={'text-xs'}>
                                                    {location.canonicalName}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div>
                <label>{getTranslation('FORM_LOCATION.LABEL_OUTPUT')}</label>
                <div>
                    {
                        (props.locations.length > 0) &&
                        <div className={'lg:grid grid-cols-2 gap-3'}>
                            {
                                props.locations.map((location, idx) => {
                                    return (
                                        <div key={idx} className={"btn btn-green text-center align-middle"} onClick={() => onClickLocation(location)}>
                                            {location.name}
                                            <div className={'text-xs'}>
                                                {location.canonicalName}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
