export class GoogleLocation {
    resourceName: string = ''
    id: string = ''
    name: string = ''
    countryCode: string = ''
    targetType: string = ''
    canonicalName: string = ''
}

export class GoogleKeywordVolumeMonthlySearch {
    year: number = 0
    month: number = 0
    count: number = 0
}

export class GoogleKeywordVolume {
    id: string = ''
    location: string = ''
    variants: string[] = []
    keyword: string = ''
    avgMonthlySearches: number | undefined = undefined
    monthlySearches: GoogleKeywordVolumeMonthlySearch[] = []
    filteredMonthlySearches: GoogleKeywordVolumeMonthlySearch[] = []
    monthlySearchesMin: number | undefined = undefined
    monthlySearchesMax: number | undefined = undefined
    averageCpc: number = 0
    competition: string = ''
    fromCache: boolean = false
}