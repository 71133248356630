import React, {useContext} from "react";
import {ConfigContext} from "../../../../../contexts/config";
import {Request} from "../../../../../models/user";
import {getTranslation} from "../../../../../intl";
import {SecureHTML} from "@boomrank/react-components";

interface Props {
    request: Request
    onChange: (request: Request) => void
}

export function ConfigFormView(props: Props) {
    let nb_keywords = props.request.keywords.filter(k => k.length > 0).length
    let {config, setConfig} = useContext(ConfigContext)

    let onSelectYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.onChange({
            ...props.request,
            nbYearsForMetrics: parseInt(event.target.value)
        })
    }

    return (
        <div>
            <div className={'lg:grid grid-cols-2 gap-6 mt-4'}>
                <div className={'mt-4'}>
                    <SecureHTML>{getTranslation('FORM_CONFIG.LABEL_NB_KEYWORDS').format(nb_keywords)}</SecureHTML>
                    <div>
                        {getTranslation('FORM_CONFIG.LABEL_LOCATIONS')}
                        <ul>
                            {
                                props.request.locations.map((location, idx) => {
                                    return (
                                        <li className={'ml-6'} key={idx}>{location.canonicalName}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center'}>
                        <input type={'checkbox'}
                               checked={config.autoDownload}
                               onChange={() => {
                                   config.autoDownload = !config.autoDownload
                                   setConfig({
                                       ...config
                                   })
                               }}
                               className={'mx-2 w-6 h-6'}
                        />
                        {getTranslation('FORM_CONFIG.LABEL_AUTO_DOWNLOAD_CSV')}
                    </div>
                    <div className={'flex items-center my-3'}>
                        <input type={'checkbox'}
                               checked={config.showMetrics}
                               onChange={() => {
                                   config.showMetrics = !config.showMetrics
                                   setConfig({
                                       ...config
                                   })
                               }}
                               className={'mx-2 w-6 h-6'}
                        />
                        {getTranslation('FORM_CONFIG.LABEL_AUTO_SHOW_METRICS')}
                    </div>
                    <div>
                        <div>
                            {
                                getTranslation('FORM_CONFIG.LABEL_MANY_YEAR').render(
                                    <select onChange={onSelectYear}
                                            defaultValue={props.request.nbYearsForMetrics}
                                            className={'p-2 mx-2'}
                                    >
                                        {
                                            [1, 2, 3, 4].map((value, idx) => {
                                                return (
                                                    <option value={value} key={idx}>
                                                        {getTranslation('FORM_CONFIG.LABEL_YEAR').format(value)}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}