import {JwtToken, PlaceHolder} from "@boomrank/react-components";
import React, {useEffect, useState} from "react";
import {LocationForm} from "../../../forms/request/location";
import {GoogleLocation} from "../../../../../models/google";
import {
    GoogleLocationResponseSuccess
} from "../../../../../services/google/responses";
import {GoogleApi} from "../../../../../services/google/api";
import {Request} from "../../../../../models/user";
import {GoogleLocationFragment} from "../../../../../services/google/fragments";
import {GoogleLocationFactory} from "../../../../../services/google/factory";

interface Props {
    token: JwtToken
    request: Request
    onChange: (request: Request) => void
}

export function LocationFormView(props: Props) {
    let allLocation = {
        resourceName: "geoTargetConstants/0",
        id: '0',
        name: "Monde",
        countryCode: "Monde",
        targetType: "All",
        canonicalName: "Monde",
    }

    let [isLoading, setIsLoading] = useState(false)
    let [suggestLocations, setSuggestLocations] = useState<GoogleLocation[]>([
        {
            resourceName: "geoTargetConstants/2250",
            id: "2250",
            name: "France",
            countryCode: "FR",
            targetType: "Country",
            canonicalName: "France",
        },
        {
            resourceName: "geoTargetConstants/2826",
            id: "2826",
            name: "Royaume-Uni",
            countryCode: "GB",
            targetType: "Country",
            canonicalName: "United Kingdom"
        },
        {
            resourceName: "geoTargetConstants/2840",
            id: "2840",
            name: "États-Unis",
            countryCode: "US",
            targetType: "Country",
            canonicalName: "United States"
        },
        {
            resourceName: "geoTargetConstants/2724",
            id: "2724",
            name: "Espagne",
            countryCode: "ES",
            targetType: "Country",
            canonicalName: "Spain"
        },
        {
            resourceName: "geoTargetConstants/2276",
            id: "2276",
            name: "Allemagne",
            countryCode: "DE",
            targetType: "Country",
            canonicalName: "Germany"
        },
        {
            resourceName: "geoTargetConstants/2380",
            id: "2380",
            name: "Italie",
            countryCode: "IT",
            targetType: "Country",
            canonicalName: "Italy"
        },
    ])

    let getLocationByIds = (ids: number[]) => {
        setIsLoading(true)
        GoogleApi.getLocationByIds(props.token, ids).then(response => {
            if (response.statusCode === 200) {
                response = response as GoogleLocationResponseSuccess

                let locations: GoogleLocation[] = []
                response.data.forEach((fragment: GoogleLocationFragment) => {
                    let location = GoogleLocationFactory.fromFragment(fragment)
                    locations.push(location)
                })
                props.onChange({
                    ...props.request,
                    locations: locations
                })
            }
            setIsLoading(false)
        })
    }

    let onSuggestedLocations = (name: string) => {
        setIsLoading(true)
        GoogleApi.getLocationByName(props.token, name).then(response => {
            if (response.statusCode === 200) {
                response = response as GoogleLocationResponseSuccess

                let locations: GoogleLocation[] = []
                response.data.forEach((fragment: GoogleLocationFragment) => {
                    let location = GoogleLocationFactory.fromFragment(fragment)
                    locations.push(location)
                })
                setSuggestLocations(locations)
            }
            setIsLoading(false)
        })
    }

    let onLocationClick = (locations: GoogleLocation[]) => {
        if(locations.length === 0) {
            locations = [
                allLocation
            ]
        }

        props.onChange({
            ...props.request,
            locations: locations,
            locationIds: locations.map(l => parseInt(l.id))
        })
    }

    useEffect(() => {
        if (props.request.locations.length === 0) {
            getLocationByIds(props.request.locationIds)
        }
    }, [props.token, props.request])

    if (isLoading) {
        return (
            <PlaceHolder className={'h-32'} />
        )
    }

    return (
        <div>
            <LocationForm
                onLocationClick={onLocationClick}
                locations={props.request.locations}
                onSuggestSubmit={onSuggestedLocations}
                suggestedLocations={suggestLocations}
            />
        </div>
    )
}