import {useEffect, useState} from "react";
import {getTranslation} from "../../../../intl";

interface InvalidKeyword {
    line: number
    keyword: string
    characters: string[]
}

interface Props {
    keywords: string[]
    content: string
    onKeywordsChange: (keywords: string[]) => void
    onContentProcess: (content: string) => void
    nbKeywordMax: number
    nbKeywords: number
}

export function KeywordForm(props: Props) {
    let invalidCharacters = [
        '\\', '!', '@', '%', ',', '*', '<', '>', ';', '.', '\''
    ]
    let [invalidKeywords, setInvalidKeywords] = useState<InvalidKeyword[]>([])

    let convertContentToKeywords = (content: string) : string[] => {
        return content.split(/\r\n|\r|\n|,/)
    }

    let checkInvalidCharacter = (keywords: string[]) => {
        let wrongKeywords: InvalidKeyword[] = []

        keywords.forEach((keyword, idx) => {
            let characters = invalidCharacters.filter((character) => {
                if (character === '\'' || character === '\\') {
                    character = `\\${character}`
                }
                let regexp = new RegExp(`[${character}]`, 'i')
                return regexp.test(keyword)
            })
            if (characters.length > 0) {
                wrongKeywords.push({
                    line: idx+1,
                    keyword: keyword,
                    characters: characters
                })
            }
        })
        setInvalidKeywords(wrongKeywords)
    }

    let onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        let value = event.target.value
        let keywords = convertContentToKeywords(value)
        props.onKeywordsChange(keywords)
    }

    useEffect(() => {
        if (props.keywords.length > 0) {
            checkInvalidCharacter(props.keywords)
        }

        if (props.content.length > 0) {
            let keywords = convertContentToKeywords(props.content)
            checkInvalidCharacter(keywords)

            props.onKeywordsChange([
                ...props.keywords,
                ...keywords
            ])
            props.onContentProcess('')
        }
    }, [props.content, props.keywords])

    return (
        <div>
            <label>
                {getTranslation('FORM_KEYWORD.INFO_TEXTAREA').format(props.nbKeywords, props.nbKeywordMax)}
            </label>
            <div className={'lg:grid grid-cols-3 gap-3 mt-2'}>
                <div className={'col-span-2'}>
                    <div className={'grid'}>
                        <div className={'col-start-1 col-end-2 row-start-1 row-end-2 py-5 invisible'}>
                            {
                                props.keywords.map((kw, idx) => {
                                    return (
                                        <div key={idx}>{kw}</div>
                                    )
                                })
                            }
                        </div>
                        <textarea className="col-start-1 col-end-2 row-start-1 row-end-2 resize-none border p-3" onChange={onChange} value={props.keywords.join('\n')}/>
                    </div>
                    <div className="text-xs text-gray-500 pt-2">
                        {getTranslation('FORM_KEYWORD.HELP_TEXTAREA')}
                    </div>
                </div>
                {
                    invalidKeywords.length > 0 &&
                    <div className={'text-red-500 border border-red-500 p-3 mb-6'}>
                        <div>{getTranslation('FORM_KEYWORD.INVALID_KEYWORDS').format(invalidKeywords.length)}</div>
                        <ul>
                            {
                                invalidKeywords.map((ikw, idx) => {
                                    return (
                                        <li key={idx} title={ikw.characters.join(' ')}>
                                            {getTranslation('FORM_KEYWORD.LINE_NUMBER').format(ikw.line)}<b >{ikw.keyword}</b>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </div>
    )
}
