import {Breadcrumb, JwtToken, Cache, PlaceHolder} from '@boomrank/react-components'
import {useLocation, useNavigate} from "react-router-dom";
import {RequestResultView} from "../../views/request/result";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Request} from "../../../../models/user";
import {getTranslation} from "../../../../intl";

interface Props {
    token: JwtToken
    cacheRequests: Cache<Request>
    onClickSearchIdea: (request: Request) => void
}

export function RequestResultRoute(props: Props) {
    let [request, setRequest] = useState<Request | null | undefined>(undefined)
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()

    useEffect(() => {
        if (params.id) {
            let r = props.cacheRequests.get([params.id])
            if (r.length > 0) {
                setRequest(r[0])
            } else {
                setRequest(null)
            }
        }
    }, [params.id])

    if (request === null) {
        return <>Error</>
    }
    if (request === undefined) {
        return <PlaceHolder className={'h-32'} />
    }

    let onEdit = (request: Request) => {
        navigate(`/dashboard/request/${request.id}/edit/`)
    }

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/`,
                        content: <i className={'fa-solid fa-home'}/>,
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                    {
                        to: `/dashboard/request/${request.id}/`,
                        content: <>{request.name}</>,
                        title: request.name
                    },
                ]}
            />

            <div className={'my-3'}>
                <RequestResultView
                    token={props.token}
                    request={request}
                    onClickSearchIdea={props.onClickSearchIdea}
                    onEdit={onEdit}
                />
            </div>
        </>
    )
}