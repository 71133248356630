import {BlueButton, GreenButton, JwtToken, Cache, PlaceHolder} from "@boomrank/react-components";
import React from "react";
import {useNavigate} from "react-router-dom";
import {RequestListView} from "./request/list";
import {Request} from "../../../models/user";
import {getTranslation} from "../../../intl";

interface Props {
    token: JwtToken
    cacheRequests: Cache<Request>
    cacheUpdater: (obj: Request) => void
    googleQuotasExceeded: boolean
    requestsLoaded: boolean
}

export function HomeView(props: Props) {
    let navigate = useNavigate()

    return (
        <>
            {
                props.googleQuotasExceeded &&
                <div className={'text-yellow-500 bg-yellow-50 border border-yellow-400 p-3'}>
                    {getTranslation('GOOGLE.QUOTAS')}
                </div>
            }
            <div className={'lg:grid grid-cols-2 gap-6 py-4'}>
                <GreenButton
                    onClick={() => navigate('/dashboard/volume/')}
                    text={
                        <div className={'flex items-center justify-center'}>
                            <i className="fa-solid fa-chart-column text-3xl mr-6"/>
                            <div>
                                {getTranslation('BUTTON.KEYWORDS_VOLUME')}
                            </div>
                        </div>
                    }
                />
                <BlueButton
                    onClick={() => navigate('/dashboard/idea/')}
                    text={
                        <div className={'flex items-center justify-center'}>
                            <i className="fa-solid fa-lightbulb text-3xl mr-6"/>
                            <div>
                                {getTranslation('BUTTON.KEYWORDS_IDEA')}
                            </div>
                        </div>
                    }
                />
            </div>
            <div className={'mt-12'}>
                <legend className={'text-3xl'}>{getTranslation('TITLE.SEARCH_HISTORY')}</legend>
                {

                    props.requestsLoaded ?
                        <RequestListView
                            token={props.token}
                            requests={props.cacheRequests.values()}
                            cacheUpdater={props.cacheUpdater}
                        />
                    :
                        <PlaceHolder className={'h-32'} />
                }

            </div>
        </>
    )
}