import React, {useEffect, useState} from "react";
import {CsvForm} from "../../../forms/request/csv";
import {KeywordForm} from "../../../forms/request/keyword";
import {Request} from "../../../../../models/user";
import {getTranslation} from "../../../../../intl";
import {SecureHTML} from "@boomrank/react-components";
import {conf, STEP_IDEAS, STEP_VOLUMES} from "../../../index";

interface Props {
    request: Request
    onRequestChange: (request: Request) => void
    onValid: (isValid: boolean) => void
}

export function KeywordsFormView(props: Props) {
    let [fileContent, setFileContent] = useState('')

    let action: 'volumes' | 'ideas' = props.request.method === 'get_keywords_ideas_volume' ? STEP_IDEAS : STEP_VOLUMES
    let nbKeywordMax = conf[action].nb_keyword_max
    let nbKeywords = props.request.keywords.filter(k => k.length > 0).length

    let onKeywordsChange = (keywords: string[]) => {
        props.onRequestChange({
            ...props.request,
            keywords: keywords
        })
    }

    useEffect(() => {
        let isValid = (
            nbKeywords > 0 &&
            nbKeywords <= nbKeywordMax
        )
        props.onValid(isValid)
    }, [props.request.keywords])

    let onReadFile = (content: string) => {
        setFileContent(content)
    }
    let onContentProcess = (content: string) => {
        setFileContent(content)
    }

    return (
        <div>
            <div className={'mt-4'}>
                <div className={'flex justify-center mb-12'}>
                    <CsvForm onReadFile={onReadFile}/>
                    <div className={'lg:ml-6 text-center text-br-green border border-digiGreen p-6'}>
                        <i className="fa-solid fa-circle-info mr-4" />
                        <SecureHTML>{getTranslation('GOOGLE.KEYWORD_ESCAPE')}</SecureHTML>
                    </div>
                </div>
                {
                    (
                        nbKeywords > nbKeywordMax
                    ) &&
                    <div className={'border border-red-500 p-6 text-red-500 bg-red-50'}>
                        <SecureHTML>{ getTranslation(`GOOGLE.LIMIT_KEYWORDS_${action.toUpperCase()}`).format(nbKeywordMax) }</SecureHTML>
                    </div>
                }
                <KeywordForm
                    keywords={props.request.keywords}
                    onKeywordsChange={onKeywordsChange}
                    content={fileContent}
                    onContentProcess={onContentProcess}
                    nbKeywordMax={nbKeywordMax}
                    nbKeywords={nbKeywords}
                />
            </div>
        </div>
    )
}