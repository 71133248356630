import {Breadcrumb, JwtToken, Cache} from '@boomrank/react-components'
import {useLocation} from "react-router-dom";
import {HomeView} from "../views/home";
import {Request} from "../../../models/user";
import {getTranslation} from "../../../intl";

interface Props {
    token: JwtToken
    cacheRequests: Cache<Request>
    cacheUpdater: (obj: Request) => void
    googleQuotasExceeded: boolean
    requestsLoaded: boolean
}

export function HomeRoute(props: Props) {
    let location = useLocation()

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/`,
                        content:<i className={'fa-solid fa-home'}/>,
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                ]}
            />
            <div className={'mt-3'}>
                <HomeView
                    token={props.token}
                    cacheRequests={props.cacheRequests}
                    cacheUpdater={props.cacheUpdater}
                    googleQuotasExceeded={props.googleQuotasExceeded}
                    requestsLoaded={props.requestsLoaded}
                />
            </div>
        </>
    )
}