import React, {useContext, useEffect, useMemo, useState} from "react";
import {ChartData, Column, ColumnType, GrayButton, GreenButton, JwtToken, RedButton} from "@boomrank/react-components";
import {Request} from "../../../../models/user";
import {KeywordTableView} from "./keyword/table";
import {ConfigContext} from "../../../../contexts/config";
import {defaultLanguage, getTranslation} from "../../../../intl";
import {ExportApi} from "../../../../services/export/api";
import {GoogleKeywordVolumeFactory} from "../../../../services/google/factory";
import {GoogleKeywordVolume} from "../../../../models/google";

interface Props {
    token: JwtToken
    request: Request
    onClickSearchIdea?: (request: Request) => void
    onEdit: (request: Request) => void
    onBack?: () => void
}

interface Row extends GoogleKeywordVolume {
    chartData: ChartData
}

export function RequestResultView(props: Props) {
    let {config} = useContext(ConfigContext)
    let [nbYearsForMetrics, setNbYearsForMetrics] = useState(props.request.nbYearsForMetrics)

    let defaultColumns: Column<Row>[] = [
        {
            header: getTranslation('VIEW_REQUEST_RESULT.TABLE_HEADER_LOCATION'),
            type: ColumnType.STRING,
            accessor:  'location',
        },
        {
            header: getTranslation('VIEW_REQUEST_RESULT.TABLE_HEADER_KEYWORDS'),
            type: ColumnType.STRING,
            accessor:  'keyword',
            cell: (row: Row, value: any) => {
                let variants = (
                    <ul>
                        {
                            row.variants.map((variant: string, idx: number) => {
                                return (
                                    <li key={idx} className={'italic'}>{variant}</li>
                                )
                            })
                        }
                    </ul>
                )
                let keyword : string | JSX.Element = row.keyword;

                if (!row.fromCache) {
                    keyword = (<strong>{keyword}</strong>)
                }
                keyword = (
                    <>
                        <>{keyword}</>
                        {variants}
                    </>
                )
                return keyword
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_RESULT.TABLE_HEADER_AVG_MONTHLY_SEARCHES'),
            type: ColumnType.NUMBER,
            accessor:  'avgMonthlySearches',
            cell: (row: Row, value: any) => {
                if (row.avgMonthlySearches === undefined) {
                    return '-'
                }
                return (
                    <div>{row.avgMonthlySearches.toLocaleString(defaultLanguage)}</div>
                )
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_RESULT.TABLE_HEADER_MIN_MONTHLY_SEARCHES'),
            type: ColumnType.NUMBER,
            accessor:  'monthlySearchesMin',
            cell: (row: Row, value: any) => {
                if (row.monthlySearchesMin === undefined) {
                    return '-'
                }
                return (
                    <div>{row.monthlySearchesMin.toLocaleString(defaultLanguage)}</div>
                )
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_RESULT.TABLE_HEADER_MAX_MONTHLY_SEARCHES'),
            type: ColumnType.NUMBER,
            accessor:  'monthlySearchesMax',
            cell: (row: Row, value: any) => {
                if (row.monthlySearchesMax === undefined) {
                    return '-'
                }
                return (
                    <div>{row.monthlySearchesMax.toLocaleString(defaultLanguage)}</div>
                )
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_RESULT.TABLE_HEADER_AVG_CPC'),
            type: ColumnType.NUMBER,
            accessor:  'averageCpc',
            cell: (row: Row, value: any) => {
                return (
                    <div>{row.averageCpc.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</div>
                )
            }
        },
    ]

    let formatter = (request: Request) : Row[] => {
        let nb_monthly_searches = 12*nbYearsForMetrics*-1
        let keywordVolumes = GoogleKeywordVolumeFactory.fromRequest(request, nbYearsForMetrics)

        let rows: any[] = []
        keywordVolumes.forEach((keywordVolume) => {
            let chartData = new ChartData()
            keywordVolume.monthlySearches.slice(nb_monthly_searches).forEach((monthly_search) => {
                chartData.add(
                    monthly_search.year.toString(),
                    new Date(monthly_search.year, monthly_search.month-1, 1).toLocaleString(defaultLanguage, { month: 'long' }),
                    monthly_search.count,
                )
            })
            rows.push({
                ...keywordVolume,
                chartData: chartData
            });
        })
        return rows;
    }

    const keywordVolumes = useMemo(()  => formatter(props.request), [props.request, nbYearsForMetrics])

    let onClickCSV = (gkv: GoogleKeywordVolume[]) => {
        if (props.request) {
            ExportApi.csv(props.token, gkv)
        }
    }

    let onClickXLSX = (gkv: GoogleKeywordVolume[]) => {
        if (props.request) {
            ExportApi.xlsx(props.token, gkv)
        }
    }

    let onClickSearchIdeas = (request: Request) => {
        if (props.onClickSearchIdea) {
            props.onClickSearchIdea(request)
        }
    }

    let onSelectYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setNbYearsForMetrics(parseInt(event.target.value))
    }

    useEffect(() => {
        setNbYearsForMetrics(props.request.nbYearsForMetrics)
        if (config.autoDownload) {
            let keywordVolumes = GoogleKeywordVolumeFactory.fromRequest(props.request, nbYearsForMetrics)
            ExportApi.csv(props.token, keywordVolumes)
        }
    }, [props.request])

    if (
        props.request.keywordVolumes.length === 0 &&
        !props.request.details
    ) {
        return (
            <div className={'text-br-red border border-br-red p-6 rounded'}>
                {getTranslation('VIEW_REQUEST_RESULT.NO_RESULT')}
            </div>
        )
    }

    return (
        <>
            {
                props.request.details &&
                <div className={'border border-red-500 bg-red-100 text-red-500 my-6 p-6'}>
                    {props.request.details}
                </div>
            }

            <div className={'lg:flex justify-between'}>
                <div>
                    {
                        props.onBack &&
                        <GrayButton
                            onClick={() => {
                                if (props.onBack) {
                                    props.onBack()
                                }
                            }}
                            text={getTranslation('BUTTON.NEXT_LOCATION')}
                        />
                    }
                </div>
                <div className={'lg:flex'}>
                    {
                        (props.onClickSearchIdea && props.request.method === 'get_keywords_volume')
                        &&
                        <GrayButton
                            onClick={() => {
                                onClickSearchIdeas(props.request)
                            }}
                            text={getTranslation('BUTTON.KEYWORDS_IDEA')}
                            className={'lg:mr-2'}
                        />
                    }
                    <RedButton
                        onClick={() => {
                            props.onEdit(props.request)
                        }}
                        text={getTranslation('BUTTON.EDIT')}
                    />
                    <div className={'lg:ml-2 flex'}>
                        <GreenButton
                            onClick={() => onClickCSV(keywordVolumes)}
                            text={getTranslation('BUTTON.EXPORT_CSV')}
                        />
                        <GreenButton
                            onClick={() => onClickXLSX(keywordVolumes)}
                            text={getTranslation('BUTTON.EXPORT_XLSX')}
                        />
                    </div>
                </div>
            </div>
            <div>
                {
                    getTranslation('FORM_CONFIG.LABEL_MANY_YEAR').render(
                        <select onChange={onSelectYear}
                                defaultValue={nbYearsForMetrics}
                                className={'p-2 mx-2'}
                        >
                            {
                                [1, 2, 3, 4].map((value, idx) => {
                                    return (
                                        <option value={value} key={idx}>
                                            {getTranslation('FORM_CONFIG.LABEL_YEAR').format(value)}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    )
                }
            </div>
            <KeywordTableView
                nbYears={nbYearsForMetrics}
                rows={keywordVolumes}
                showExpanded={config.showMetrics}
                columns={defaultColumns}
            />
        </>
    )
}