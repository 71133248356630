import React, {useMemo} from "react";
import {Request} from "../../../../../models/user";
import {Column, ColumnType, GreenButton, PaginateAdvancedTable, TableV2} from "@boomrank/react-components";
import {useNavigate} from "react-router-dom";
import './index.scss'
import classNames from "classnames";
import {EditableField} from "../../../dummies/editable";
import {defaultLanguage, getTranslation, translator} from "../../../../../intl";
import {SelectableRequest} from "./models";
import {UseTableRowProps} from "react-table";



interface Props {
    requests: SelectableRequest[]
    onClickCsv: (request: Request) => void
    onClickXls: (request: Request) => void
    onChangeRequestName: (request: Request, name: string) => void
    onCheck: (request: SelectableRequest) => void
}

export function RequestTableView(props: Props) {
    let navigate = useNavigate()

    let onClickCsv = (request: Request) => {
        if(props.onClickCsv) {
            props.onClickCsv(request)
        }
    }
    let onClickXls = (request: Request) => {
        if(props.onClickXls) {
            props.onClickXls(request)
        }
    }

    let onChangeRequestName = (request: Request, name: string) => {
        props.onChangeRequestName(request, name)
    }

    let onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        let requests = props.requests.filter(r => r.request.id === event.target.value)
        if (requests.length > 0) {
            props.onCheck(requests[0])
        }
    }

    let defaultColumns: Column<SelectableRequest>[] = [
        {
            header: getTranslation('VIEW_REQUEST_LIST.TABLE_HEADER_CREATED_AT'),
            type: ColumnType.DATE,
            accessor:  'request.createdAt',
            cell: ({request}: SelectableRequest, value: any) => {
                return request.createdAt.toLocaleString(defaultLanguage)
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_LIST.TABLE_HEADER_NAME'),
            type: ColumnType.STRING,
            accessor:  'request.name',
            cell: ({request}: SelectableRequest, value: any) => {
                return (
                    <EditableField text={request.name} title={getTranslation('VIEW_REQUEST_LIST.TITLE_NAME')} onClickOutside={(name: string) => {
                        onChangeRequestName(request, name)
                    }} />
                )
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_LIST.TABLE_HEADER_KEYWORDS'),
            type: ColumnType.STRING,
            accessor:  'request.keywords',
            cell: ({request}: SelectableRequest, value: any) => {
                let keywords = request.keywords
                if (keywords.length > 3) {
                    return `${keywords.slice(0,3).join(', ')} [+${keywords.length - 3}]`
                }
                return keywords.join(', ');
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_LIST.TABLE_HEADER_METHOD'),
            type: ColumnType.STRING,
            accessor:  'request.method',
            cell: ({request}: SelectableRequest, value: any) => {
                let classname = classNames("fa-solid", {
                    'fa-solid fa-chart-column': request.method === 'get_keywords_volume',
                    'fa-solid fa-lightbulb': request.method !== 'get_keywords_volume',
                    'text-green-600 -scale-x-100': request.duration < 5 && request.details.length === 0,
                    'text-yellow-600': request.duration > 5 && request.details.length === 0,
                    'text-red-600': request.duration > 10 || request.details.length !== 0
                })
                let title = request.details
                if (request.details.length === 0) {
                    title = getTranslation('VIEW_REQUEST_LIST.MEMORY_USAGE')
                        .format(request.duration, request.ramMb)
                }

                if (request.method === 'get_keywords_volume') {
                    title = '{0} : {1}'.format(
                        getTranslation('BUTTON.KEYWORDS_VOLUME'),
                        title
                    )
                } else {
                    title = '{0} : {1}'.format(
                        getTranslation('BUTTON.KEYWORDS_IDEA'),
                        title
                    )
                }

                return (
                    <>
                        <i className={classname} title={title} />
                    </>
                )
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_LIST.TABLE_HEADER_EXPORT'),
            type: ColumnType.STRING,
            accessor:  'request.id',
            cell: ({request}: SelectableRequest, value: any) => {
                return(
                    <>
                        {
                            request.details.length === 0 &&
                            <div className={'grid grid-cols-2 gap-3'}>
                                <a
                                    className={'cursor-pointer'}
                                    onClick={() => {
                                        onClickCsv(request)
                                    }}
                                >
                                    {getTranslation('VIEW_REQUEST_LIST.FILE_CSV_LABEL')}
                                </a>
                                <a
                                    className={'cursor-pointer'}
                                    onClick={() => {
                                        onClickXls(request)
                                    }}
                                >
                                    {getTranslation('VIEW_REQUEST_LIST.FILE_XLSX_LABEL')}
                                </a>
                            </div>
                        }
                    </>
                )
            }
        },
        {
            header: getTranslation('VIEW_REQUEST_LIST.TABLE_HEADER_ACTIONS'),
            type: ColumnType.STRING,
            accessor:  'request.username',
            cell: ({request}: SelectableRequest, value: any) => {
                return(
                    <div className={'grid grid-cols-2 gap-3'}>
                        <div title={getTranslation('VIEW_REQUEST_LIST.BUTTON_RESULT')}>
                            <GreenButton
                                onClick={() => {
                                    navigate(`/dashboard/request/${request.id}/`)
                                }}
                                text={
                                    <i className="fa-solid fa-table-list" />
                                }
                            />
                        </div>
                        <div title={getTranslation('VIEW_REQUEST_LIST.BUTTON_EDIT')}>
                            <GreenButton
                                onClick={() => {
                                    navigate(`/dashboard/request/${request.id}/edit/`)
                                }}
                                text={
                                    <i className="fa-solid fa-pen-to-square" />
                                }
                            />
                        </div>
                    </div>
                )
            }
        },
    ]

    const sortBy = useMemo(
        () => [
            {
                id: "createdAt",
                desc: true
            }
        ],
        []
    );

    return (
        <div id={'request-table'}>
            <TableV2
                rows={props.requests}
                columns={defaultColumns}
                toggleRow={(item: UseTableRowProps<SelectableRequest>) => {
                    return(
                        <input type={'checkbox'} value={item.original.request.id} checked={item.original.selected} onChange={onCheck} />
                    )
                }}
            >
                <PaginateAdvancedTable
                    initialState={{
                        sortBy: sortBy,
                        pageSize: 100
                    }}
                    translator={translator}
                />
            </TableV2>
        </div>
    )
}
