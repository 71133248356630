import {Request} from "../../../models/user";
import {IdeaView} from "../views/idea";

interface Props {
    onCreate: (request: Request) => void
}
export function IdeaRoute(props: Props) {
    return (
        <IdeaView
            onCreate={props.onCreate}
        />
    )
}