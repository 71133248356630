import {Api, JwtToken, Response} from "@boomrank/react-components";
import {
    GoogleError,
    GoogleKeywordIdeaVolumeResponseSuccess,
    GoogleKeywordVolumeResponseSuccess,
    GoogleLocationResponseSuccess, GoogleUsageResponse
} from "./responses";
import {endpoints} from "../api";
import {Request} from "../../models/user";


export class GoogleApi {
    static getLocationByName(token: JwtToken, locationName: string) : Promise<Response | GoogleLocationResponseSuccess | GoogleError> {
        let url = endpoints.google.v2022.location+'?name[]='+locationName;
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static getLocationByIds(token: JwtToken, locationId: number[]) : Promise<Response | GoogleLocationResponseSuccess | GoogleError> {
        let url = endpoints.google.v2022.location+'?id[]='+locationId.join('&id[]=')
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        );
    }

    static getKeywordVolume(token: JwtToken, request: Request) : Promise<Response | GoogleKeywordVolumeResponseSuccess | GoogleError> {
        let keywords = request.keywords.filter(k => k.length > 0)
        let url = endpoints.google.v2022.keyword_volume;

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    keywords: keywords,
                    locations: request.locationIds,
                    nb_years_for_metrics: request.nbYearsForMetrics
                })
            }
        );
    }

    static getKeywordIdeaVolume(token: JwtToken, request: Request) : Promise<Response | GoogleKeywordIdeaVolumeResponseSuccess | GoogleError> {
        let keywords = request.keywords.filter(k => k.length > 0)
        let url = endpoints.google.v2022.keyword_ideas_volume;

        return Api.call(
            url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    keywords: keywords,
                    locations: request.locationIds,
                    nb_years_for_metrics: request.nbYearsForMetrics
                })
            }
        );
    }

    static usage(token: JwtToken) : Promise<GoogleUsageResponse>{
        let url = endpoints.google.usage;

        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
            }
        );
    }
}