import React, {useEffect, useState} from "react";
import {Request} from "../../../../models/user";
import {useParams} from "react-router";
import {JwtToken, Cache} from "@boomrank/react-components";
import {useNavigate} from "react-router-dom";
import {RequestEditView} from "../../views/request/edit";

interface Props {
    token: JwtToken
    cacheRequests: Cache<Request>
    onEdit: (request: Request) => void
}

export function RequestEditRoute(props: Props) {
    let [isLoading, setIsLoading] = useState(true);
    let [request, setRequest] = useState<Request>();
    let params = useParams();
    let navigate = useNavigate();

    let onEdit = (request: Request) => {
        props.onEdit({
            ...request
        })
        navigate('/dashboard/request/new/')
    }

    useEffect(() => {
        if (params.id) {
            let r = props.cacheRequests.get([params.id])
            if (r.length > 0) {
                setRequest(r[0])
            }
            setIsLoading(false)
        }
    }, [params.id])

    if (!isLoading) {
        if (!request) {
            return <>Error</>
        }
        return (
            <RequestEditView  onEdit={onEdit} request={request} token={props.token} />
        )
    }
    return <></>
}