import {csvParse} from "d3-dsv";
import {getTranslation} from "../../../../intl";

interface Props {
    onReadFile: (content: string) => void
}

export function CsvForm(props: Props) {
    let file: File
    let content: string | ArrayBuffer | null

    let reader = new FileReader();
    reader.addEventListener('load', (e: ProgressEvent<FileReader>) => {
        content = e.target!.result
        processFile()
    });

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target.files.length > 0) {
            file = event.target.files[0]
            reader.readAsText(file)
        }
    }

    let processFile = () => {
        if (content) {
            if (file.type === 'text/plain') {
                props.onReadFile(content.toString())
            }

            if (file.type === 'text/csv') {
                let rows = csvParse(content.toString())

                if (rows.columns.length > 0) {
                    let first_column_name = rows.columns[0]
                    let values: string[] = rows.map((d: any) => {
                        return d[first_column_name].toString()
                    })
                    props.onReadFile(values.join('\n'))
                }
            }
        }
    }


    return (
        <div>
            <label className={'btn btn-gray text-center'} htmlFor={'file'}>
                <div className={'flex'}>
                    <i className="text-3xl pl-0 p-3 fa-solid fa-upload" />
                    <div>
                        <div>{getTranslation('FORM_CSV.BUTTON_UPLOAD_CSV_LABEL')}</div>
                        <i className={'text-xs'}>{getTranslation('FORM_CSV.BUTTON_UPLOAD_CSV_SUB')}</i>
                    </div>
                </div>
            </label>
            <input id="file" type="file" className="hidden" onChange={onChange}/>
        </div>
    )
}
