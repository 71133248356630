import {createContext} from "react";

export class Config {
    autoDownload = false
    showMetrics = false
}

interface ConfigContextType {
    config: Config
    setConfig: (config: Config) => void
}

export const ConfigContext = createContext<ConfigContextType>({
    config: new Config(),
    setConfig: (config: Config) : void => {}
});