import {Breadcrumb, RedButton, GrayButton} from '@boomrank/react-components'
import {useLocation, useNavigate} from "react-router-dom";
import {Request} from "../../../../models/user";
import {Navigate} from "react-router-dom";
import React from "react";
import {ConfigFormView} from "../../views/request/config/form";
import {getTranslation} from "../../../../intl";

interface Props {
    request?: Request
    onRequestChange: (request: Request) => void
    onSubmit: () => void
}

export function RequestConfigRoute(props: Props) {
    let navigate = useNavigate()
    let location = useLocation()

    if (!props.request) {
        return (
            <Navigate to={'/dashboard/volume/'}/>
        )
    }

    let label = getTranslation('BREADCRUMB.KEYWORD').format(
        getTranslation(`TYPE.${props.request.method.toUpperCase()}`)
    )


    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/`,
                        content: <i className={'fa-solid fa-home'}/>,
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                    {
                        to: `/dashboard/request/new/`,
                        content: <>{label}</>,
                        title: label
                    },
                    {
                        to: `/dashboard/request/new/location/`,
                        content: <>{getTranslation('BREADCRUMB.LOCATION')}</>,
                        title: getTranslation('BREADCRUMB.LOCATION')
                    },
                    {
                        to: `/dashboard/request/new/config/`,
                        content: <>{getTranslation('BREADCRUMB.CONFIG')}</>,
                        title: getTranslation('BREADCRUMB.CONFIG')
                    },
                ]}
            />

            <div className={'mt-3'}>
                <div className={'lg:flex justify-between'}>
                    <div>
                        <GrayButton text={getTranslation('BUTTON.PREVIOUS_LOCATION')} onClick={() => {
                            navigate('/dashboard/request/new/location/')
                        }}/>
                    </div>
                    <div className={'lg:flex'}>
                        <RedButton text={getTranslation('BUTTON.SEARCH')} onClick={props.onSubmit}/>
                    </div>
                </div>
                <ConfigFormView
                    request={props.request}
                    onChange={props.onRequestChange}
                />
            </div>
        </>
    )
}