import {Request} from "../../../models/user";
import {VolumeView} from "../views/volume";

interface Props {
    onCreate: (request: Request) => void
}
export function VolumeRoute(props: Props) {
    return (
        <VolumeView onCreate={props.onCreate} />
    )
}