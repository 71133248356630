import {Request} from "../../../../models/user";
import {JwtToken, PlaceHolder} from "@boomrank/react-components";
import {GoogleApi} from "../../../../services/google/api";
import {GoogleLocationResponseSuccess} from "../../../../services/google/responses";
import {GoogleLocation} from "../../../../models/google";
import {GoogleLocationFragment} from "../../../../services/google/fragments";
import {GoogleLocationFactory} from "../../../../services/google/factory";
import {useEffect, useState} from "react";

interface Props {
    token: JwtToken
    request: Request
    onEdit: (request: Request) => void
}

export function RequestEditView(props: Props) {
    let [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getLocationByIds(props.request.locationIds)
    }, [props.request])

    let getLocationByIds = (ids: number[]) => {
        setIsLoading(true)
        GoogleApi.getLocationByIds(props.token, ids).then(response => {
            if (response.statusCode === 200) {
                response = response as GoogleLocationResponseSuccess

                let locations: GoogleLocation[] = []
                response.data.forEach((fragment: GoogleLocationFragment) => {
                    let location = GoogleLocationFactory.fromFragment(fragment)
                    locations.push(location)
                })
                props.onEdit({
                    ...props.request,
                    locations: locations
                })
            }
            setIsLoading(false)
        })
    }

    if (isLoading) {
        return (
            <PlaceHolder className={'h-32'} />
        )
    }
    return <></>
}