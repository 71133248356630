import {Route} from "react-router";
import {Link, Routes} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DashboardTemplate, JwtToken, PlaceHolder, ProfileMenu} from "@boomrank/react-components";
import {KpiCollector} from "./kpiCollector";
import {AuthApi} from "../services/auth/api";
import {MeSuccess} from "../services/auth/responses";
import {AccountFactory} from "../services/auth/factory";
import {Account} from "../models/user";
import {useCookies} from "react-cookie";
import classNames from "classnames";
import {v4} from "uuid";
import {getTranslation} from "../intl";

interface Props {
    token: JwtToken
}

export function Dashboard(props: Props) {
    let [me, setMe] = useState<Account | null>(null);
    let [cookies] = useCookies(['easteregg']);

    let getMe = () => {
        AuthApi.getMe(props.token)
            .then(response => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    response = response as MeSuccess
                    let me = AccountFactory.fromFragment(response.data)
                    setMe(me)
                }
            })
    }

    useEffect(() => {
        getMe()
    }, [])

    if (!me) {
        return <PlaceHolder className={'h-32'}/>
    }

    let menuItems = [
        <ProfileMenu
            key={v4()}
            user={me.user}
            items={[
                <a href={'https://boomrank.io/dashboard/profile/'}
                   target={'_blank'}>
                    {getTranslation('ACCOUNT.PROFILE')}
                </a>,
                <Link to={'/logout'}>
                    {getTranslation('ACCOUNT.LOGOUT')}
                </Link>
            ]}
        />
    ]
    if (me.user.email.indexOf('@digimood.com') !== -1) {
        menuItems.unshift(
            <a href={'https://faq.digimood.io/docs/documentation-seo/boomrank-9905/'}
               target={'_blank'}
               key={v4()}
               title={'Besoin d\'aide ?'}
               className={'mr-4 py-4 w-14 text-center rounded-full bg-br-green text-white cursor-pointer no-underline'}
            >
                <i className="fa-regular fa-circle-question" />
            </a>,
        )
    }


    return (
        <div className={classNames({
            'easteregg': cookies.easteregg === "1"
        })}>
            <DashboardTemplate
                headline={
                    <h1 className={"text-white"} dangerouslySetInnerHTML={{
                        __html: getTranslation('TEMPLATE.HI').format([me.user.firstname])
                    }} />
                }
                logo={
                    <Link
                        to={'/dashboard/'}
                        className={'uppercase font-bold no-underline text-sm md:text-lg lg:text-2xl'}
                    >
                        KPI <span className={'text-gray-400'}>Collector</span>
                    </Link>
                }
                menuItems={menuItems}
            >
                <Routes>
                    <Route path={'*'} element={
                        <KpiCollector
                            token={props.token}
                            me={me}
                        />
                    }/>
                </Routes>
            </DashboardTemplate>
        </div>
    )
}