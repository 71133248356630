import React  from 'react';
import {TooltipProps} from 'recharts';
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";
import {defaultLanguage, getTranslation} from "../../../../intl";

export function CustomTooltip (props: TooltipProps<ValueType, NameType>) {
    if (props.active && props.payload && props.payload.length > 0) {
        return (
            <ul className="bg-white p-3 border" style={{'backgroundColor': '#e5e7eb'}}>
                {
                    props.payload.map((payload, idx) => {
                        if (payload.value! !== 0) {
                            return (
                                <li key={idx} className={'flex items-center'} style={{'color': '#333'}}>
                                    <span
                                        className={'p-1 rounded rounded-full inline-block mr-2'}
                                          style={{'backgroundColor': payload.color}}
                                    />
                                    {
                                        getTranslation('VIEW_REQUEST_RESULT.GRAPH_TOOLTIP_VOLUME').format(
                                            payload.value!.toLocaleString(defaultLanguage),
                                            props.label,
                                            payload.dataKey
                                        )
                                    }
                                </li>
                            )
                        }
                    })
                }
            </ul>
        )
    }
    return <></>
}