import React, {useCallback} from "react";
import {UseTableRowProps} from "react-table";
import {CustomTooltip} from "../../../dummies/charts";
import {BarChart, Column, PaginateAdvancedTable, TableV2} from "@boomrank/react-components";
import {translator} from "../../../../../intl";

interface Props<T> {
    rows: any[]
    columns: Column<T>[]
    showExpanded: boolean
    nbYears: number
}

export function KeywordTableView<T>(props: Props<T>) {
    const renderRowSubComponent = useCallback(
        (row: UseTableRowProps<any>) => {
            return (
                <div className={'h-96'}>
                    <BarChart
                        data={row.original.chartData}
                        minTickGap={200}
                        strokeDasharray={"3, 3"}
                        tooltip={<CustomTooltip />}
                        showLegend={true}
                    />
                </div>
            )
        },
        []
    )

    return (
        <>
            <TableV2
                columns={props.columns}
                rows={props.rows}
                toggleRow={(row) => {
                    return(
                        <span {...row.getToggleRowExpandedProps()}>
                            {
                                row.isExpanded ?
                                    <i className="text-3xl lg:text-xl text-br-green fa-regular fa-square-minus" /> :
                                    <i className="text-3xl lg:text-xl text-br-green fa-solid fa-square-plus" />
                            }
                        </span>
                    )
                }}
            >
                <PaginateAdvancedTable
                    renderRowSubComponent={renderRowSubComponent}
                    translator={translator}
                    showExpanded={props.showExpanded}
                />
            </TableV2>
        </>
    )
}
