import {LANG_FR} from '@boomrank/react-components'

export const LANG = {
    ...LANG_FR,
    TEMPLATE: {
        HI: `Bonjour <b>{0}</b>,`
    },
    ACCOUNT: {
        LOGOUT: 'Déconnexion',
        PROFILE: 'Profil',
    },
    BREADCRUMB: {
        HOMEPAGE: 'Accueil',
        LOCATION: 'Localisation',
        CONFIG: 'Paramêtres avancés',
        KEYWORD: '{0} de mots-clés'
    },
    TYPE: {
        GET_KEYWORDS_VOLUME: 'Volume',
        GET_KEYWORDS_IDEAS_VOLUME: 'Idée'
    },
    TITLE: {
        SEARCH_HISTORY: 'Historique des recherches'
    },
    BUTTON: {
        SEARCH: 'Rechercher',
        EDIT: 'Modifier',
        EXPORT_CSV: '.CSV',
        EXPORT_XLSX: '.XLSX',

        KEYWORDS_IDEA: 'Recherche d\'idées',
        KEYWORDS_VOLUME: 'Recherche de volumes',

        PREVIOUS_KEYWORD: '< Modifier les mots-clés',
        PREVIOUS_LOCATION: '< Modifier la localisation',
        NEXT_LOCATION: '> Choisir une localisation',
        NEXT_CONFIG: '> Paramêtres avancés',
    },

    FORM_CSV: {
        BUTTON_UPLOAD_CSV_LABEL: 'Importer depuis un fichier' ,
        BUTTON_UPLOAD_CSV_SUB: '(.txt ou .csv)' ,
    },

    FORM_KEYWORD: {
        INFO_TEXTAREA: 'Saisie manuelle : {0}/{1} mots-clés',
        HELP_TEXTAREA: 'Séparer chaque mots-clés par une virgule ou un retour à la ligne.',
        INVALID_KEYWORDS: '{0} mots clés invalides : ',
        LINE_NUMBER: 'Ligne {0} : ',
    },

    FORM_LOCATION: {
        LABEL_INPUT: 'Recherche :',
        HELP_INPUT: '[Entrée] pour valider.',
        LABEL_SUGGEST: 'Suggestions :',
        LABEL_OUTPUT: 'Cibles :',
    },

    FORM_CONFIG: {
        LABEL_NB_KEYWORDS : 'Recherche <b>{0}</b> mots-clés',
        LABEL_LOCATIONS : 'Localisations :',
        LABEL_AUTO_DOWNLOAD_CSV : 'Je souhaite télécharger automatiquement le résultat en .csv.',
        LABEL_AUTO_SHOW_METRICS : 'Je souhaite afficher directement la saisonnalité dans les résultats.',
        LABEL_YEAR : '{0} année(s)',
        LABEL_MANY_YEAR: 'Je souhaite {0} de saisonnalité.'
    },

    VIEW_REQUEST_RESULT: {
        GRAPH_TOOLTIP_VOLUME: '{0} recherches en {1} {2}',
        TABLE_HEADER_LOCATION: 'Cible',
        TABLE_HEADER_KEYWORDS: 'Mots-clés',
        TABLE_HEADER_AVG_MONTHLY_SEARCHES: 'Volume de recherche mensuel moyen',
        TABLE_HEADER_MIN_MONTHLY_SEARCHES: 'Volume de recherche mensuel minimum',
        TABLE_HEADER_MAX_MONTHLY_SEARCHES: 'Volume de recherche mensuel maximum',
        TABLE_HEADER_AVG_CPC: 'CPC actuel moyen',
        NO_RESULT: 'Aucun résultat pour votre recherche',
    },

    VIEW_REQUEST_LIST: {
        SEARCH_INPUT: 'Recherche',
        TABLE_HEADER_CREATED_AT: 'Créée le',
        TABLE_HEADER_NAME: 'Nom',
        TABLE_HEADER_KEYWORDS: 'Mots-clés',
        TABLE_HEADER_METHOD: 'Type',
        TABLE_HEADER_EXPORT: 'Fichiers',
        TABLE_HEADER_ACTIONS: 'Actions',

        FILE_CSV_LABEL: 'CSV',
        FILE_XLSX_LABEL: 'XLSX',
        MEMORY_USAGE: '{0}s. ({1} MB)',

        BUTTON_EDIT: 'Modifier',
        BUTTON_RESULT: 'Résultat',
        TITLE_NAME: 'Changer le nom',
    },

    GOOGLE: {
        KEYWORD_ESCAPE: 'Google interdit l\'usage des caractères <b>!@%,*{}&lt;&gt;;.\'</b>',
        QUOTAS: 'Les quotas journalier d\'appels à Google sont atteints.',
        LIMIT_KEYWORDS_VOLUMES: 'Google limite à <b>{0} mots-clés</b>',
        LIMIT_KEYWORDS_IDEAS: 'Google limite à <b>{0} mots-clés</b>',
    },

    REACT_TABLE: {
        'SORTED': 'Filtrer par {0}',
        'SORTED_ASC': 'Filtrage par ordre croissant',
        'SORTED_DESC': 'Filtrage par ordre décroissant',
    },
    REACT_TABLE_PAGINATION: {
        'FIRST_PAGE': 'Aller à la 1ère page',
        'PREVIOUS_PAGE': 'Page précédente',
        'NEXT_PAGE': 'Page suivante',
        'LAST_PAGE': 'Aller à la dernière page',
        'SHOW_DATA': `Afficher {0} données`,
    },
}
