import {endpoints} from "../api";
import {JwtToken} from "@boomrank/react-components";
import {GoogleKeywordVolume} from "../../models/google";

export class ExportApi {
    static csv(token: JwtToken, gkv: GoogleKeywordVolume[]): Promise<any> {
        return this.export(token, endpoints.export.csv, 'csv', gkv)
    }
    static xlsx(token: JwtToken, gkv: GoogleKeywordVolume[]): Promise<any> {
        return this.export(token, endpoints.export.xlsx, 'xlsx', gkv)
    }

    static export(token: JwtToken, url:string, extension: string, gkv: GoogleKeywordVolume[]): Promise<any> {
        let name = 'kpi-collector'
        let data: any[] = []
        let columns: string[] =  [
            'location',
            'keyword',
            'variants',
            'avg_monthly_searches',
            'monthly_searches_min',
            'monthly_searches_max',
            'average_cpc',
            'competition',
        ]

        let backup = [...gkv]
        backup.sort((a: GoogleKeywordVolume, b: GoogleKeywordVolume) => {
            return b.filteredMonthlySearches.length - a.filteredMonthlySearches.length
        })

        if (backup.length > 0) {
            let extra = backup[0].filteredMonthlySearches.map(ms => {
                let month = ('0' + ms.month).slice(-2)
                return `${ms.year}/${month}`
            })
            columns = [
                ...columns,
                ...extra
            ]

            data = backup.map(kv => {
                let v = [
                    kv.location,
                    kv.keyword,
                    kv.variants.join(','),
                    kv.avgMonthlySearches,
                    kv.monthlySearchesMin,
                    kv.monthlySearchesMax,
                    kv.averageCpc,
                    kv.competition,
                ]
                let e = kv.filteredMonthlySearches.map(ms => ms.count)

                return [
                    ...v,
                    ...e
                ]
            })
        }
        return fetch(
            url, {
                method: 'POST',
                body: JSON.stringify({
                    'data': data,
                    'columns': columns
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        ).then((response) => response.blob())
            .then((blob) => {
                let a = document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = `${name}.${extension}`;
                a.click();
            }).catch((err) => {
                console.log(err);
            })
    }
}