import {Account, Request} from "../../models/user";
import {MeFragment, RequestFragment} from "./fragments";
import {GoogleKeywordVolumeFactory} from "../google/factory";

export class AccountFactory {
    static fromFragment = (fragment: MeFragment) : Account => {
        let me = new Account()
        me.id = fragment.id
        me.createdAt = new Date(fragment.created_at)
        me.user.id = fragment.user.id
        me.user.lastname = fragment.user.lastname
        me.user.firstname = fragment.user.firstname
        me.user.email = fragment.user.email
        return me
    }
}

export class RequestFactory {
    static fromFragment = (fragment: RequestFragment) : Request => {
        let keyword_volumes = fragment.data.map((fragment) => {
            return GoogleKeywordVolumeFactory.fromFragment(fragment)
        })

        let request = new Request()
        request.id = fragment.id
        request.createdAt = new Date(fragment.created_at)
        request.name = fragment.name
        request.username = fragment.username
        request.locationIds = fragment.locations
        request.nbYearsForMetrics = fragment.nb_years_for_metrics
        request.keywords = fragment.keywords
        request.method = fragment.method
        request.keywordVolumes = keyword_volumes
        request.details = fragment.details
        request.ramMb = fragment.ram_mb
        request.duration = fragment.duration
        return request
    }
}