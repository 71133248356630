import {GoogleKeywordVolume, GoogleLocation} from "./google";

export class User {
    id: number = 0
    firstname: string = ''
    lastname: string = ''
    email: string = ''
}

export class Account {
    id: number = 0
    createdAt: Date = new Date()
    user = new User()
}

export class Request {
    id: string = ''
    createdAt: Date = new Date()
    name: string = ''
    username: string = ''
    locationIds: number[] = []
    locations: GoogleLocation[] = []
    nbYearsForMetrics: number = 1
    keywords: string[] = []
    method: string = ''
    keywordVolumes: GoogleKeywordVolume[] = []
    details = ''
    ramMb = 0
    duration = 0
}