import {useState, useRef, useEffect} from "react";

interface Props {
    text: string
    onClickOutside: (text: string) => void
    title?: string
}

export function EditableField (props: Props) {
    let [isVisible, setIsVisible] = useState(false);
    let [text, setText] = useState(props.text);
    let ref = useRef(null);

    let onClickOutside = (event: any) => {
        if (ref.current && !(ref.current! as any).contains(event.target)) {
            setIsVisible(false);
            props.onClickOutside(text)
        }
    };

    useEffect(() => {
        document.addEventListener('click', onClickOutside, true);
        return () => {
            document.removeEventListener('click', onClickOutside, true);
        };
    }, []);


    let onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setIsVisible(!isVisible);
    }
    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value)
    }

    let onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key.toLowerCase() === 'enter') {
            setIsVisible(false);
            props.onClickOutside(text)
        }
    }

    return (
        <>
            {
                isVisible ?
                    <input ref={ref} className={'bg-white p-3 border rounded'} type={'text'} autoFocus={true} placeholder={text} onChange={onChange} onKeyUp={onKeyUp} defaultValue={text} /> :
                    <div className={'cursor-pointer'} title={props.title} onClick={onClick} >{text}</div>
            }
        </>
    )
}