import {Breadcrumb, GreenButton, RedButton, GrayButton, JwtToken} from '@boomrank/react-components'
import {useLocation, useNavigate} from "react-router-dom";
import {Request} from "../../../../models/user";
import {Navigate} from "react-router-dom";
import React from "react";
import {LocationFormView} from "../../views/request/location/form";
import {getTranslation} from "../../../../intl";

interface Props {
    request?: Request
    token: JwtToken
    onRequestChange: (request: Request) => void
    onSubmit: () => void
}

export function RequestLocationRoute(props: Props) {
    let navigate = useNavigate()
    let location = useLocation()

    if (!props.request) {
        return (
            <Navigate to={'/dashboard/volume/'}/>
        )
    }

    let label = getTranslation('BREADCRUMB.KEYWORD').format(
        getTranslation(`TYPE.${props.request.method.toUpperCase()}`)
    )

    return (
        <>
            <Breadcrumb
                location={location}
                links={[
                    {
                        to: `/dashboard/`,
                        content: <i className={'fa-solid fa-home'}/>,
                        title: getTranslation('BREADCRUMB.HOMEPAGE')
                    },
                    {
                        to: `/dashboard/request/new/`,
                        content: <>{label}</>,
                        title: label
                    },
                    {
                        to: `/dashboard/request/new/location/`,
                        content: <>{getTranslation('BREADCRUMB.LOCATION')}</>,
                        title: getTranslation('BREADCRUMB.LOCATION')
                    },
                ]}
            />

            <div className={'mt-3'}>
                <div className={'lg:flex justify-between'}>
                    <div>
                        <GrayButton text={getTranslation('BUTTON.PREVIOUS_KEYWORD')} onClick={() => {
                            navigate('/dashboard/request/new/')
                        }}/>
                    </div>
                    <div className={'lg:flex'}>
                        <GreenButton className={'lg:mr-2'} text={getTranslation('BUTTON.NEXT_CONFIG')} onClick={() => {
                            navigate('/dashboard/request/new/config/')
                        }}/>
                        <RedButton text={getTranslation('BUTTON.SEARCH')} onClick={props.onSubmit}/>
                    </div>
                </div>
                <LocationFormView
                    request={props.request}
                    onChange={props.onRequestChange}
                    token={props.token}
                />
            </div>
        </>
    )
}