import {endpoints} from "../api";
import {Request} from "../../models/user";
import {MeSuccess, RequestListSuccess, RequestUpdateSuccess} from "./responses";
import {TokenError, AuthApi as BoomrankAuthApi, Api, JwtToken, Response} from "@boomrank/react-components";


export class AuthApi {
    static getMe (token: JwtToken) : Promise<MeSuccess | TokenError>  {
        return BoomrankAuthApi.getMe(token)
    }

    static getRequests (token: JwtToken, baseUrl?: string) : Promise<RequestListSuccess | TokenError>  {
        let url = endpoints.auth.request.list;
        if (baseUrl) {
            url = baseUrl;
        }
        return Api.call(
            url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        )
    }

    static updateRequest (token: JwtToken, request: Request) : Promise<RequestUpdateSuccess | TokenError>  {
        let url = endpoints.auth.request.update.replace(':pk', request.id);
        return Api.call(
            url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                },
                body: JSON.stringify({
                    'name': request.name
                })
            }
        )
    }

    static deleteRequest (token: JwtToken, request: Request) : Promise<Response | TokenError>  {
        let url = endpoints.auth.request.delete.replace(':pk', request.id);
        return Api.call(
            url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.access}`,
                }
            }
        )
    }
}