import {endpoints as BoomrankEndpoints} from '@boomrank/react-components'
let BACKEND_API = process.env.REACT_APP_BACKEND_API

export const endpoints = {
    ...BoomrankEndpoints,
    ...{
        auth: {
            request: {
                list: BACKEND_API + '/api/account/request/',
                update: BACKEND_API + '/api/account/request/:pk/',
                delete: BACKEND_API + '/api/account/request/:pk/',
            }
        }
    },
    google: {
        usage: BACKEND_API+'/api/google/usage/',
        v2022: {
            location: BACKEND_API+'/api/google/v2022/location/',
            keyword_volume: BACKEND_API+'/api/google/v2022/keyword_volume/',
            keyword_ideas_volume: BACKEND_API+'/api/google/v2022/keyword_ideas_volume/',
        },
    },
    export: {
        csv: BACKEND_API+'/api/export/csv/',
        xlsx: BACKEND_API+'/api/export/xlsx/',
    }
}