import {JwtToken, RedButton, GrayButton} from "@boomrank/react-components";
import {useEffect, useState} from "react";
import {AuthApi} from "../../../../services/auth/api";
import {Request} from "../../../../models/user";
import {RequestTableView} from "./table";
import {getTranslation} from "../../../../intl";
import {ExportApi} from "../../../../services/export/api";
import {GoogleKeywordVolumeFactory} from "../../../../services/google/factory";
import {SelectableRequest} from "./table/models";

interface Props {
    token: JwtToken
    requests: Request[]
    cacheUpdater: (obj: Request, remove?: boolean) => void
}

export function RequestListView(props: Props) {
    let [requests, setRequests] = useState<SelectableRequest[]>([])

    useEffect(() => {
        setRequests(
            formatToSelectableRequests(props.requests)
        )
    }, [props.requests])

    let onClickCsv = (request: Request) => {
        let keywordVolumes = GoogleKeywordVolumeFactory.fromRequest(request, request.nbYearsForMetrics)
        ExportApi.csv(props.token, keywordVolumes)
    }
    let onClickXls = (request: Request) => {
        let keywordVolumes = GoogleKeywordVolumeFactory.fromRequest(request, request.nbYearsForMetrics)
        ExportApi.xlsx(props.token, keywordVolumes)
    }

    let onChangeRequestName = (request: Request, name: string) => {
        if (name.length > 0) {
            request.name = name
            AuthApi.updateRequest(props.token, request).then((response) => {
                if (response.statusCode >= 200 && response.statusCode < 300) {
                    props.cacheUpdater(request)
                }
            })
        }
    }

    let formatToSelectableRequests = (requests: Request[], selected = false) : SelectableRequest[] => {
        return requests.map(r => {
            return {
                request: r,
                selected: selected
            }
        })
    }

    let onCheck = (selectableRequest: SelectableRequest) => {
        let editedRequests = requests.map(r => {
            if (r.request.id === selectableRequest.request.id) {
                r.selected = !r.selected
            }
            return r
        })
        setRequests([
            ...editedRequests
        ])
    }

    let onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let search = event.target.value
        if (search.length === 0) {
            setRequests(
                formatToSelectableRequests(props.requests)
            )
        } else {
            let regexp = RegExp(search, 'gi')
            let r =props.requests.filter((r) => {
                if (r.name.match(regexp)) {
                    return r
                }
                let keywords = r.keywords.filter((k) => {
                    if (k.match(regexp)) {
                        return k
                    }
                })
                if (keywords.length > 0) {
                    return r
                }
            })
            setRequests(
                formatToSelectableRequests(r)
            )
        }
    }

    let deleteSelectedRequests = () => {
        requests.filter(r => r.selected).forEach(r => {
            AuthApi.deleteRequest(props.token, r.request).then(response => {
                props.cacheUpdater(r.request, true)
            })
        })
    }

    let selectAll = () => {
        setRequests(
            formatToSelectableRequests(props.requests, true)
        )
    }

    let deselectAll = () => {
        setRequests(
            formatToSelectableRequests(props.requests, false)
        )
    }

    return (
        <div>
            <div className={'flex justify-between py-6'}>
                <div className={'flex'}>
                    {
                        requests.length > 0 &&
                        <>
                            {
                                requests.filter(r => r.selected).length === requests.length ?
                                    <GrayButton text={
                                        <i className={'fa-regular fa-square-check'} />
                                    } onClick={() => {
                                        deselectAll()
                                    }} />
                                    :
                                    <GrayButton text={
                                        <i className={'fa-regular fa-square'} />
                                    } onClick={() => {
                                        selectAll()
                                    }} />
                            }
                        </>
                    }
                    {
                        requests.filter(r => r.selected).length > 0 &&
                            <RedButton text={
                                <i className={'fa-solid fa-trash'} />
                            } onClick={() => {
                                deleteSelectedRequests()
                            }} />
                    }
                </div>
                <input placeholder={getTranslation('VIEW_REQUEST_LIST.SEARCH_INPUT')} onChange={onChange} className={'border rounded p-3'}/>
            </div>
            <RequestTableView
                requests={requests}
                onClickCsv={onClickCsv}
                onClickXls={onClickXls}
                onChangeRequestName={onChangeRequestName}
                onCheck={onCheck}
            />
        </div>
    )
}